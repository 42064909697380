import React, {useState, Fragment} from 'react';
import Slider from "react-slick";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';
import ReactCardFlip from 'react-card-flip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CardActions } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { CardContent } from '@mui/material';

import { useMutation } from "react-query";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import apiClient from "../../request/http-common";
import CreditCardIcon from '@mui/icons-material/CreditCard';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ListItemText from '@mui/material/ListItemText';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Cards = () => {
  const user = useSelector((state) => state.user.user);
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: user.currency, currencySign: 'standard' }).format(amount);
  const fundingBal = useSelector((state) => state.wallet.fundingBalance);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const cards = useSelector((state) => state.history.cards);

  const [isFlipped, setFlipped] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setErros] = useState('');
    const [crdType, setCardType] = useState('');

  const handleClick = (e) => {
      e.preventDefault();
      setFlipped(!isFlipped);
    }

    const initialCards = {
        number1: 'XXXX',
        number2: 'XXXX',
        number3: 'XXXX',
        number4: 'XXXX',
        name: 'XXXXX XXXXXX',
        expiry: 'XX/XX',
        cvv: 'XXX',
        type: 'visa'
    }

    const updateCradDetails = (card) => {
        setCurrentCard({...currentCard, 
            number1: card.number1,
            number2: card.number2,
            number3: card.number3,
            number4: card.number4,
            name: user.name,
            expiry: '08/2029',
            cvv: '745',
            type: card.card_type
        })
    }

    const [currentCard, setCurrentCard] = useState(initialCards);
    const getDate = (date) => new Date(date).toDateString();

    const handleClickOpen = () => {
       
        if (isAuth && user.name) {
          setOpen(true);
        }
        
      };

      const handleClose = () => {
        setOpen(false);
      };


      
      const { isLoading: isApplying, mutate: applyForCard } = useMutation(
        async () => {
          return await apiClient.post(`/api/card-application`, {
            card_type: crdType,

          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
              }            
          },
          onError: (err) => {
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                setErros(myerror.errors) 

                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }
                

                
          },
        }
      );

  return (
    <div>
        <Grid container mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={5}>
        <Card sx={{marginTop: '75px', paddingX: '10px'}} elevation={0}>
        <div>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                <Box>
                <Card onClick={handleClick} sx={{marginX: '5px', paddingBottom: '0px', borderRadius: '10px'}}>
                    <Typography px={2} mb={-5} py={1}  sx={{display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: 16, position: 'relative', color: '#fff'}} gutterBottom variant="subtitle2" component="div">
                        
                        <Typography sx={{color: '#fff', fontSize: 18}}  variant="h5" color="text.secondary">
                            FIRST CAPITAL
                        </Typography>

                        <img style={{width: '40px'}} src={`${currentCard.type === 'visa'? 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1659807904/1000px-Visa_Inc._logo.svg.png': 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1660665383/mastercardlogo.png'}`}/>
                        
                    </Typography>

                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height: 250 }}
                        image="https://res.cloudinary.com/dg8pxsdme/image/upload/v1700160102/unitcard1_kazavw.png"
                        
                        alt="wallet"
                        />
                    <Typography sx={{display: 'flex', paddingX: '20px', justifyContent: 'space-evenly', color: '#fff', fontSize: 18, marginTop: '-100px'}}  variant="h5" color="text.secondary">
                        <Typography sx={{marginX: '10px'}}>{currentCard.number1}</Typography> 
                        <Typography sx={{marginX: '10px'}}>{currentCard.number2}</Typography>
                        <Typography sx={{marginX: '10px'}}>{currentCard.number3}</Typography>
                        <Typography sx={{marginX: '10px'}}>{currentCard.number4}</Typography>
                    </Typography>  

                    <Typography sx={{display: 'flex', textAlign: 'left', paddingX: '20px', justifyContent: 'space-between', color: '#fff', fontSize: 18, marginTop: '8px'}}  variant="h5" color="text.secondary">
                        
                        <Typography variant="caption">
                            Cardholder Name
                            <Typography variant="subtitle1" display="block" gutterBottom>
                                {user.name}
                            </Typography>
                        </Typography> 

                        <Typography variant="caption">
                            Expiration
                            <Typography variant="subtitle1" display="block" gutterBottom>
                            {currentCard.expiry}
                            </Typography>
                        </Typography>

                    </Typography>                 
                </Card>
                </Box>

                <Box>
                <Card onClick={handleClick} sx={{marginX: '5px', borderRadius: '10px'}}>
                    <Typography px={2} py={1}  sx={{marginBottom: '-75px', display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', fontSize: 16, position: 'relative', color: '#fff'}} gutterBottom variant="subtitle2" component="div">
                        
                        <Typography sx={{color: '#fff', fontSize: 18}}  variant="h5" color="text.secondary">
                            Security Code
                            <Typography variant="subtitle1" display="block" gutterBottom>
                            {currentCard.cvv}
                            </Typography>
                        </Typography>
                    </Typography>
                    <CardMedia
                            component="img"
                            sx={{ width: '100%', height: 235 }}
                            image="https://res.cloudinary.com/dg8pxsdme/image/upload/v1700160107/unitcard3_c04jra.png"
                            alt="wallet"
                        />
                        {/* <Typography mb={-6} sx={{color: '#000000', textAlign: 'left', fontWeight: 'bold', transform: 'translate(0px, -120px)', bgcolor: '#fff', boxShadow: 2, width: 'fit-content', paddingY: '5px', paddingX: '9px', fontSize: 24}}  variant="h5" color="primary">
                    {formatPrice(fundingBal)}
                        </Typography> */}
                </Card>
                </Box>
            </ReactCardFlip>
        </div>
      </Card>
      
        <Box sx={{display: 'flex', justifyContent: 'flex-start', marginTop: '20px', paddingX: '20px'}}>
        <Button onClick={handleClickOpen} startIcon={<CreditCardIcon />} variant="contained" size='large' color="primary">
        Apply For Card
      </Button>
        </Box>
      </Grid>


      <Grid item xs={12} md={4} sx={{marginTop: '75px', paddingX: '10px'}}>
        <Typography mt={4} px={3} sx={{ marginTop: '10px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        CARD LIST
        </Typography>
        {cards.length > 0 &&
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

        {cards.map(card => (
        <Fragment key={card.id}>
        <ListItem sx={{cursor: 'pointer'}} onClick={()=>{updateCradDetails(card);}} alignItems="flex-start">
        <ListItemAvatar>
            
            {card.status === 'pending' &&
            <Avatar  sx={{ bgcolor: '#f7a21d' }}>
            <PendingIcon/>
            </Avatar>
            }
            
            {card.status === 'approved' &&
            <Avatar  sx={{ bgcolor: '#37dc8a' }}>
            <CheckCircleIcon/>
            </Avatar>
            }

            {card.status === 'declined' &&
            <Avatar  sx={{ bgcolor: '#dd0a22' }}>
            <DangerousIcon/>
            </Avatar>
            
            }

            {card.status === 'completed' &&
            <Avatar  sx={{ bgcolor: '#ccc6c6' }}>
            <DoneAllIcon/>
            </Avatar>
            
            }
            
            
        </ListItemAvatar>

        <ListItemText
            primary={`${card.number1} ${card.number2} ${card.number3} ${card.number4}`}
            secondary={
            <React.Fragment>
                <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
                >
                {card.card_type}
                </Typography>
            </React.Fragment>
            }
        />
        

            <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'center', alignItems: 'right'}}>
                <Typography color={card.status === 'pending'? 'secondary': 'primary.success'}  edge="end">
                {card.status}
                </Typography>
            </Box>

        
        </ListItem>
        <Divider  component="li" variant="inset" />
        </Fragment>
        ))}
        </List>
        }
      </Grid>

      </Grid>



      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogContent>
          
        <Card sx={{ borderRadius: '9px', height: '100%', margin: 0}}>

          <Typography my={3} px={4} sx={{textAlign: 'center'}}  variant="body2" color="secondary">
          Kindly select type of card
          </Typography>

          <CardContent sx={{textAlign: 'center'}}>
          
<FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
        <InputLabel htmlFor="card_type">Card Type</InputLabel>
        {(error !== '' && error.card_type) ?
            <Select
                error
                onChange={(e)=>setCardType(e.target.value)}
                labelId="card_type"
                id="card_type"
                name="card_type"
                label={`Card Type`}
                helperText={error.card_type}
                >
                <MenuItem selected value=''>
                    Card Type
                </MenuItem> 
                <MenuItem selected value="visa">
                    Visa Card
                </MenuItem>

                <MenuItem selected value="Master Card">
                    Master Card
                </MenuItem>             
                
            </Select>
        :
            <Select
                onChange={(e)=>setCardType(e.target.value)}
                labelId="card_type"
                name="card_type"
                id="card_type"
                label={`Card Type`}
                >
                <MenuItem selected value="">
                    Card Type
                </MenuItem>   

                <MenuItem selected value="Visa Card">
                    Visa Card
                </MenuItem>

                <MenuItem selected value="Master Card">
                    Master Card
                </MenuItem>  
            </Select>
        }
        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
        {error !== '' && error.card_type}
        </Typography>
    </FormControl>
          </CardContent>

          <CardActions> 
            <Button onClick={handleClose}  fullWidth={true} edge="end" size="small" color="error" variant="contained"> 
            Cancel
            </Button>

            <Button onClick={applyForCard} fullWidth={true} edge="end" size="small" color="primary" variant="contained"> 
            {isApplying? 'applying....': 'apply'}
            </Button> 
          </CardActions>
        </Card>
        
        </DialogContent>
        
      </Dialog>

    </div>
  )
}

export default Cards
