import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: JSON.parse(sessionStorage.getItem('authstatus')) || false,
    authToken : localStorage.getItem('auth-token') || ''
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAuthStatus: (state, action) =>{
             
            if (action.payload === 'authenticated') {
                state.isAuthenticated = true;
                sessionStorage.setItem('authstatus', true);
            }else{
                state.isAuthenticated = false;
                localStorage.setItem('authstatus', false);
                localStorage.removeItem('user');
                localStorage.removeItem('fundings');
                localStorage.setItem('funding-balance', 0);
                localStorage.setItem('subscriptionBalance', 0);
                localStorage.removeItem('subscriptions');
                localStorage.removeItem('notifications');
                localStorage.removeItem('auth-token');
            } 
            
        } ,
        
        setToken: (state, action) =>{
             
                state.authToken = action.payload;
                localStorage.setItem('auth-token', state.authToken);            
        } 
    }
})

export const {setAuthStatus, setToken} = authSlice.actions;
export default authSlice.reducer;